import React from "react";
import PageHeader from "./PageHeader";

export default function ScreenLayout({
  children,
  title,
  ingress,
  backHref,
}: {
  children: React.ReactNode;
  title: string;
  ingress?: React.ReactNode;
  backHref?: string;
}) {
  return (
    <div className="login-container">
      <div className="login-box-container">
        {backHref ? (
          <a
            href={backHref}
            style={{
              color: "white",
              marginBottom: "1em",
              display: "block",
            }}
          >
            <span
              style={{
                display: "inline-block",
                transform: "scaleX(-1)",
                marginRight: ".25em",
              }}
            >
              ➔
            </span>
            Tilbake
          </a>
        ) : null}
        <div className="login-box" id="login-box">
          <PageHeader title={title}>{ingress}</PageHeader>
          <div>{children}</div>
        </div>
      </div>
    </div>
  );
}

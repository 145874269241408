import React, { useEffect } from "react";
import { Route, Switch, useHistory } from "react-router-dom";
import ErrorScreen from "./shared/misc/ErrorScreen";
import { WebSocketClientProvider } from "./shared/api/WebSocketClient";
import CheckoutRoutes from "./checkout/CheckoutRoutes";

function useVirtualPageViewTracking() {
  const history = useHistory();

  useEffect(() => {
    const unsubscribe = history.listen(() => {
      (window as any).dataLayer?.push({ event: "virtualPageView" });
    });

    return () => unsubscribe();
  }, [history]);
}

export function AppRoutes() {
  useVirtualPageViewTracking();

  return (
    <Switch>
      <Route path="/checkout">
        <WebSocketClientProvider>
          <CheckoutRoutes />
        </WebSocketClientProvider>
      </Route>
      <Route>
        <ErrorScreen message="Nå har du kommet feil!" />
      </Route>
    </Switch>
  );
}

import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
  useContext,
} from "react";

type Unsubscribe = () => void;

interface WebSocketClientContextProps {
  onMessage(callback: (event: MessageEvent) => void): Unsubscribe;
  onOpen(callback: (event: Event) => void): Unsubscribe;
  send<T>(message: T): Promise<void>;
  status: ConnectionStatus;
}

export const WebSocketClientContext = React.createContext<WebSocketClientContextProps | null>(
  null
);

export enum ConnectionStatus {
  Connecting = 0,
  Connected = 1,
  Disconnected = 2,
  Reconnecting = 3,
  Failed = 4,
}

export function WebSocketClientProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [status, setStatus] = useState(ConnectionStatus.Connecting);
  const [socket, setSocket] = useState<WebSocket | null>(null);

  const [onMessageSubscribers, setOnMessageSubscribers] = useState<
    ((event: MessageEvent) => void)[]
  >([]);
  const [onOpenSubscribers, setOnOpenSubscribers] = useState<
    ((event: Event) => void)[]
  >([]);
  const [queue, setQueue] = useState<
    { resolve: Function; reject: Function; message: any }[]
  >([]);

  const connectionAttempts = useRef(0);

  const createSocket = useMemo(() => {
    function attemptReconnect() {
      console.log(
        `Attempting reconnect after ${connectionAttempts.current} attempts...`
      );

      if (connectionAttempts.current < 3) {
        connectionAttempts.current += 1;
        setSocket(createSocketInner(ConnectionStatus.Reconnecting));
      } else {
        setStatus(ConnectionStatus.Disconnected);
      }
    }

    function createSocketInner(status?: ConnectionStatus) {
      const {
        location: { protocol, hostname, port },
      } = document;

      const wsPort = port || (protocol === "https:" ? "443" : "80");

      const uri = `${protocol.replace("http", "ws")}//${hostname}:${wsPort}/ws`;

      console.log(`Connecting to WebSocket on ${uri}`);

      try {
        const newSocket = new WebSocket(uri);

        setStatus(status || ConnectionStatus.Connecting);

        newSocket.onopen = () => {
          connectionAttempts.current = 0;
          setStatus(ConnectionStatus.Connected);
        };

        newSocket.onclose = (event) => {
          const NORMAL_CLOSURE_CODE = 1000; // normal closure only happens when we explicitly call .close() which we only call when we unmount the component
          if (event.code !== NORMAL_CLOSURE_CODE) {
            attemptReconnect();
          }
        };

        newSocket.onerror = (event: Event) => {
          console.log("onerror", event);

          setStatus(ConnectionStatus.Disconnected);
        };

        return newSocket;
      } catch (error) {
        setStatus(ConnectionStatus.Failed);
        console.error("Could not create WebSocket");
        console.log(error);
        return null;
      }
    }

    return createSocketInner;
  }, []);

  useEffect(() => {
    if (!socket) {
      const newSocket = createSocket();

      setSocket(newSocket);
    }

    return () => {
      if (socket) socket.close();
    };
  }, [createSocket, socket]);

  useEffect(() => {
    if (!socket) return;

    const onMessage = (event: MessageEvent) => {
      for (const callback of onMessageSubscribers) callback(event);
    };

    socket.addEventListener("message", onMessage);

    return () => socket.removeEventListener("message", onMessage);
  }, [socket, onMessageSubscribers]);

  useEffect(() => {
    if (!socket) return;

    const onOpen = (event: Event) => {
      for (const callback of onOpenSubscribers) callback(event);
    };

    socket.addEventListener("open", onOpen);

    return () => socket.removeEventListener("open", onOpen);
  }, [socket, onOpenSubscribers]);

  useEffect(() => {
    if (socket && status === ConnectionStatus.Connected && queue.length) {
      for (const x of queue) {
        socket.send(JSON.stringify(x.message));
        x.resolve();
      }

      setQueue([]);
    }
  }, [status, queue, socket]);

  const onMessage = useCallback((callback: (event: MessageEvent) => void) => {
    setOnMessageSubscribers((subscribers) => [...subscribers, callback]);

    return () =>
      setOnMessageSubscribers((subscribers) =>
        subscribers.filter((sub) => sub !== callback)
      );
  }, []);

  const onOpen = useCallback((callback: (event: Event) => void) => {
    setOnOpenSubscribers((subscribers) => [...subscribers, callback]);

    return () =>
      setOnOpenSubscribers((subscribers) =>
        subscribers.filter((sub) => sub !== callback)
      );
  }, []);

  const send = useCallback(function <T>(message: T) {
    return new Promise<void>((resolve, reject) =>
      setQueue((q) => [...q, { resolve, reject, message }])
    );
  }, []);

  const client: WebSocketClientContextProps = useMemo(
    () => ({
      onMessage,
      onOpen,
      send,
      status,
    }),
    [onMessage, onOpen, send, status]
  );

  return (
    <WebSocketClientContext.Provider value={client}>
      {children}
      {/*
      {status === ConnectionStatus.Disconnected ||
      status === ConnectionStatus.Reconnecting ? (
        <BottomPanel>
          <span>{getStatusText(status)}</span>
          {status === ConnectionStatus.Disconnected ? (
            <LinkButton
              onClick={() => {
                setSocket(createSocket());
              }}
              title="Koble til på nytt"
            />
          ) : null}
        </BottomPanel>
      ) : null}
      {status === ConnectionStatus.Failed ? (
        <BottomPanel>
          <span>{connectionFailedMessage}</span>
        </BottomPanel>
      ) : null}
       */}
    </WebSocketClientContext.Provider>
  );
}

export function useWebSocketClient() {
  const context = useContext(WebSocketClientContext);

  if (context == null)
    throw new Error(
      "No WebSocketClientContext found! Have you wrapped your app in a WebSocketClientProvider?"
    );

  return context;
}

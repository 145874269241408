import React, { useEffect } from "react";
import { CheckoutStatus, Checkout } from "./CheckoutContext";
import ScreenLayout from "../shared/layout/ScreenLayout";
import PrimaryButton from "../shared/elements/PrimaryButton";
import { Summary } from "./CheckoutLandingPage";
import CenteredSpinner from "../shared/misc/CenteredSpinner";

export default function CheckoutCompletePage({
  checkout,
}: {
  checkout: Checkout;
}) {
  const isCanceled = checkout.status === CheckoutStatus.Canceled;
  const message = isCanceled
    ? "Gå tilbake for å prøve på nytt."
    : "Betalingen er fullført.";
  const buttonTitle = isCanceled ? "Gå tilbake" : "Fortsett";
  const title = isCanceled ? "Betalingen ble avbrutt" : "Takk for din betaling";

  const doRedirect = checkout.status === CheckoutStatus.Paid;

  useEffect(() => {
    if (doRedirect) {
      const timeout = setTimeout(
        () => (document.location.href = checkout.completeUrl),
        500
      );

      return () => clearTimeout(timeout);
    }
  }, [doRedirect, checkout.completeUrl]);

  if (doRedirect) {
    return (
      <ScreenLayout title={title}>
        <Summary checkout={checkout} />
        <div>
          <p>
            Vi tar deg <a href={checkout.completeUrl}>videre</a>!
            <br />
          </p>
          <CenteredSpinner />
        </div>
      </ScreenLayout>
    );
  }

  return (
    <ScreenLayout title={title}>
      <Summary checkout={checkout} />
      <div>
        <p>{message}</p>
        <PrimaryButton href={checkout.completeUrl}>{buttonTitle}</PrimaryButton>
      </div>
    </ScreenLayout>
  );
}

import "./CheckoutActions.css";
import React, { useState } from "react";
import { useCheckoutState, Checkout } from "./CheckoutContext";
import {
  CardPaymentButton,
  VippsPaymentButton,
} from "./create-payment/CreatePayment";
import {
  ContinueVippsPaymentLink,
  ContinueCreditCardPaymentLink,
} from "./ContinuePayment";
import { CheckoutClient, PaymentType } from "./CheckoutClient";
import { ChoiceSeparator } from "../shared/elements/ChoiceSeparator";
import CreatePaymentButton from "./create-payment/CreatePaymentButton";
import PrimaryButton from "../shared/elements/PrimaryButton";

function CancelButton({ checkout }: { checkout: Checkout }) {
  return (
    <PrimaryButton kind="secondary" href={checkout.completeUrl}>
      Avbryt
    </PrimaryButton>
  );
}

function CheckoutActionList({
  client,
  checkout,
}: {
  client: CheckoutClient;
  checkout: Checkout;
}) {
  const actions = [];

  if (!checkout.canContinuePayment) {
  }

  if (checkout.canContinuePayment && checkout.vippsPaymentUrl)
    actions.push(
      <ContinueVippsPaymentLink
        key="ContinueVippsPaymentLink"
        state={checkout}
      />
    );
  if (checkout.canContinuePayment && checkout.creditCardPaymentUrl)
    actions.push(
      <ContinueCreditCardPaymentLink
        key="ContinueCreditCardPaymentLink"
        state={checkout}
      />
    );
  if (!checkout.creditCardPaymentUrl && checkout.creditCardIsSupported)
    actions.push(<CardPaymentButton key="CardPaymentButton" client={client} />);
  if (!checkout.vippsPaymentUrl && checkout.vippsIsSupported)
    actions.push(
      <VippsPaymentButton key="VippsPaymentButton" client={client} />
    );
  return (
    <>
      {actions.map((x, i) =>
        i !== 0 ? (
          <React.Fragment key={x.key as any}>
            <ChoiceSeparator />
            {x}
          </React.Fragment>
        ) : (
          x
        )
      )}
      <CancelButton checkout={checkout} />
    </>
  );
}

export default function CheckoutActions({
  client,
}: {
  client: CheckoutClient;
}) {
  const state = useCheckoutState();

  const showFullForm = state.creditCardIsSupported && state.vippsIsSupported;

  const [paymentType, setPaymentType] = useState<PaymentType>(state.defaultPaymentMethod);

  const showVippsLink =
    state.canContinuePayment &&
    state.vippsPaymentUrl &&
    paymentType === "vipps";

  const showCardLink =
    state.canContinuePayment &&
    state.creditCardPaymentUrl &&
    paymentType === "card";

  const payButton = showVippsLink ? (
    <ContinueVippsPaymentLink state={state}>Betal</ContinueVippsPaymentLink>
  ) : showCardLink ? (
    <ContinueCreditCardPaymentLink state={state}>
      Betal
    </ContinueCreditCardPaymentLink>
  ) : (
    <CreatePaymentButton client={client} paymentType={paymentType}>
      Betal
    </CreatePaymentButton>
  );

  if (showFullForm) {
    return (
      <div className="CheckoutActions">
        <div className="PaymentTypes">
          <label className="PaymentTypeOption">
            <input
              type="radio"
              name="paymentType"
              value="card"
              checked={paymentType === "card"}
              onChange={() => setPaymentType("card")}
            />
            <span>Betal med kort</span>
            <span className="PaymentTypeLogo">
              {" "}
              <img src="/resources/kort.svg" width="33px" alt="Betalingskort" />
            </span>
          </label>
          <label className="PaymentTypeOption">
            <input
              type="radio"
              name="paymentType"
              value="vipps"
              checked={paymentType === "vipps"}
              onChange={() => setPaymentType("vipps")}
            />
            <span>Betal med Vipps</span>
            <span className="PaymentTypeLogo">
              <img src="/resources/vipps.svg" width="65px" alt="Vipps-logo" />
            </span>
          </label>
        </div>
        <div>
          {payButton}
          <CancelButton checkout={state} />
        </div>
      </div>
    );
  }

  return <CheckoutActionList client={client} checkout={state} />;
}

import React from "react";
import VersionInfo from "./shared/misc/VersionInfo";
import { BrowserRouter as Router } from "react-router-dom";
import { AppRoutes } from "./AppRoutes";

function Footer() {
  return (
    <footer style={{ textAlign: "center", color: "white", marginTop: "2em" }}>
      <p style={{ fontSize: "14px" }}>
        Sikker betaling med Swedbank Pay
        <br />
        <VersionInfo />
      </p>
    </footer>
  );
}

function App() {
  return (
    <div className="page-container d-flex flex-column">
      <header>
        <div className="naf-logo-background">
          <img
            id="naf-logo"
            src="https://res.cloudinary.com/nafmedier/image/upload/q_auto:best/f_svg/v1/Logo/logo"
            alt="NAF logo"
          />
        </div>
      </header>
      <main>
        <Router>
          <AppRoutes />
        </Router>
        <Footer />
      </main>
    </div>
  );
}

export default App;

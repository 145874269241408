import React from "react";
import CenteredSpinner from "./CenteredSpinner";
import "./LoadingOverlay.css";

export default function LoadingOverlay() {
  return (
    <div className="LoadingOverlay">
      <CenteredSpinner />
    </div>
  );
}

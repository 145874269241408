import React from "react";
import "./ChoiceSeparator.css";

export function ChoiceSeparator() {
  return (
    <div className="ChoiceSeparator">
      <hr />
      <div>
        <span>eller</span>
      </div>
    </div>
  );
}

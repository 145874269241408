export const THOUSAND_SEP = " "; // Should use space. Source: https://www.sprakradet.no/sprakhjelp/Skriveregler/Dato/#store
export const DECIMAL_LINE = "–";

export function formatMoneyCompact(value: number) {
  const isWhole = value === Math.floor(value);

  return isWhole ? value.toString() : value.toFixed(2).split(".").join(",");
}

export function formatMoney(
  value: number,
  opts = { thousandSep: THOUSAND_SEP, decimalLine: DECIMAL_LINE }
) {
  const isWhole = value === Math.floor(value);

  const [wholePart, decimals] = isWhole
    ? [value.toString(), opts.decimalLine]
    : value.toFixed(2).toString().split(".");

  const groupedWhole = wholePart.replace(
    /\B(?=(\d{3})+(?!\d))/g,
    opts.thousandSep
  );

  return [groupedWhole, decimals].join(",");
}

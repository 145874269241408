import React, { useCallback, useMemo, useEffect } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import {
  useSignature,
  useCheckoutContext,
  Checkout,
  CheckoutStatus,
} from "./CheckoutContext";
import ScreenLayout from "../shared/layout/ScreenLayout";
import { useCheckoutApi } from "./CheckoutClient";
import PrimaryButton from "../shared/elements/PrimaryButton";
import { useApiClient } from "../shared/api/ApiClient";
import LoadingScreen from "../shared/misc/LoadingScreen";

export enum PaymentState {
  Ready = "Ready",
  Failed = "Failed",
  Aborted = "Aborted",
  Pending = "Pending",
}

export default function CheckoutPaymentPage() {
  const { getUrl } = useCheckoutApi();
  const { checkoutId, setState, state, isSubscribed } = useCheckoutContext();
  const signature = useSignature();
  const client = useApiClient<{
    payment: {
      state: PaymentState;
    };
    checkout: Checkout;
  }>();

  const { externalReference } = useParams<{ externalReference: string }>();
  const { fetch } = client;

  const checkoutPath = useMemo(
    () => `/checkout/${checkoutId}?signature=${signature}`,
    [checkoutId, signature]
  );

  const history = useHistory();

  const refresh = useCallback(async () => {
    if (!externalReference)
      throw new Error('Param "externalReference" not found!');
    const result = await fetch(getUrl(`payments/${externalReference}`));
    if (!result) return;

    setState(result.checkout);

    if (
      result.payment.state === PaymentState.Aborted ||
      result.payment.state === PaymentState.Failed
    ) {
      history.replace(checkoutPath);
    }
  }, [fetch, checkoutPath, history, externalReference, getUrl, setState]);

  useEffect(() => {
    if (state.status > CheckoutStatus.None) history.replace(checkoutPath);
  }, [state, checkoutPath, history]);

  useEffect(() => {
    refresh();
  }, [refresh]);


  useEffect(() => {
    console.debug("UseEffect timeout", client.isLoading, isSubscribed);
    if (client.isLoading || isSubscribed) {
      console.debug("UseEffect timeout, setting timout", client.isLoading, isSubscribed);
      const timeout = setTimeout(
        () => (refresh()),
        20000
      );

      return () => clearTimeout(timeout);
    }
  }, [client.isLoading, isSubscribed, refresh]);

  if (client.isLoading || isSubscribed) {
    return <LoadingScreen title="Behandler" />;
  }


  return (
    <ScreenLayout title="Betaling">
      <p>Betalingen er under behandling.</p>
      {client.error ? <p>{client.error.message}</p> : null}
      <PrimaryButton onClick={refresh} isLoading={client.isLoading}>
        Hent på nytt
      </PrimaryButton>
      <p>
        <Link to={checkoutPath}>Tilbake</Link>
      </p>
    </ScreenLayout>
  );
}

import React from "react";
import { Switch, Route } from "react-router-dom";
import CheckoutLandingPage from "./CheckoutLandingPage";
import {
  CheckoutContextProvider,
} from "./CheckoutContext";
import CheckoutPaymentPage from "./CheckoutPaymentPage";

export default function CheckoutRoutes() {
  return (
    <Route path="/checkout/:checkoutId">
      <CheckoutContextProvider>
        <Switch>
          <Route path="/checkout/:checkoutId/payment/:externalReference">
            <CheckoutPaymentPage />
          </Route>
          <Route>
            <CheckoutLandingPage />
          </Route>
        </Switch>
      </CheckoutContextProvider>
    </Route>
  );
}

import React from "react";
import ScreenLayout from "../layout/ScreenLayout";
import CenteredSpinner from "./CenteredSpinner";

export default function LoadingScreen({ title }: { title?: string }) {
  return (
    <ScreenLayout title={title || "Laster"}>
      <div>
        <CenteredSpinner />
      </div>
    </ScreenLayout>
  );
}

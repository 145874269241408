import { useEffect, useCallback, useMemo } from "react";
import {
  Checkout,
  useCheckoutContext,
  useSignature,
} from "./CheckoutContext";
import { useApiClient, ApiError } from "../shared/api/ApiClient";

export type PaymentType = "card" | "vipps";

export interface CheckoutClient {
  isLoading: boolean;
  error?: ApiError;
  createPayment(type: PaymentType): Promise<Checkout | undefined>;
  cancelPayments(): Promise<Checkout | undefined>;
}

export function useCheckoutApi() {
  const { checkoutId } = useCheckoutContext();
  const signature = useSignature();

  return {
    getUrl: useCallback(
      (path: string) => `checkout/${checkoutId}/${path}?signature=${signature}`,
      [checkoutId, signature]
    ),
  };
}

export function useCheckoutClient(): CheckoutClient {
  const client = useApiClient<Checkout>();
  const { setState } = useCheckoutContext();
  const { data, fetch, isLoading, error } = client;

  useEffect(() => {
    if (data) setState(data);
  }, [data, setState]);

  const { getUrl } = useCheckoutApi();

  const createPayment = useCallback(
    (type: PaymentType) =>
      fetch(
        getUrl(`payments/${type}`),
        "post",
        type === "vipps"
          ? {
              phoneNumber: "",
            }
          : {}
      ),
    [fetch, getUrl]
  );

  const cancelPayments = useCallback(
    () => fetch(getUrl(`payments`), "delete"),
    [fetch, getUrl]
  );

  return useMemo(
    () => ({
      isLoading,
      error,
      createPayment,
      cancelPayments,
    }),
    [isLoading, error, createPayment, cancelPayments]
  );
}

import React from "react";

export default function PageHeader({
  title,
  children,
}: {
  title: string;
  children?: React.ReactNode;
}) {
  return (
    <div className="login-header">
      <h1 id="login-title">{title}</h1>
      {children ? <>{children}</> : null}
    </div>
  );
}

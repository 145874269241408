import React from "react";
import CreatePaymentButton from "./CreatePaymentButton";
import { CheckoutClient } from "../CheckoutClient";

export const CardPaymentButton = ({ client }: { client: CheckoutClient }) => (
  <CreatePaymentButton client={client} paymentType="card">
    Betal med kort
  </CreatePaymentButton>
);

export const VippsPaymentButton = ({ client }: { client: CheckoutClient }) => (
  <CreatePaymentButton client={client} paymentType="vipps">
    Betal med Vipps
  </CreatePaymentButton>
);
import React from "react";
import CenteredSpinner from "../misc/CenteredSpinner";

interface PrimaryButtonBaseProps<T> extends React.ButtonHTMLAttributes<T> {
  children: React.ReactNode;
  isLoading?: boolean;
  disabled?: boolean;
  type?: "submit" | "button";
  name?: string;
  kind?: "primary" | "secondary";
}

interface PrimaryButtonLinkProps
  extends PrimaryButtonBaseProps<HTMLAnchorElement> {
  href: string;
}

interface PrimarButtonProps extends PrimaryButtonBaseProps<HTMLButtonElement> {
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

function isLinkProps(
  props: PrimaryButtonLinkProps | PrimarButtonProps
): props is PrimaryButtonLinkProps {
  return (props as PrimaryButtonLinkProps).href !== undefined;
}

export default function PrimaryButton(
  props: PrimaryButtonLinkProps | PrimarButtonProps
) {
  const className = `btn btn-block ${
    !props.kind || props.kind === "primary" ? "btn-primary" : ""
  }`;

  const { children, isLoading } = props;

  const content = isLoading ? <CenteredSpinner /> : children;
  const disabled = isLoading || props.disabled;

  if (isLinkProps(props)) {
    const { children: _, isLoading: __, href, ...rest } = props;

    return (
      <a className={className} href={href} disabled={disabled} {...rest}>
        {content}
      </a>
    );
  }

  const { children: _, isLoading: __, onClick, ...rest } = props;

  return (
    <button
      className={className}
      onClick={onClick}
      disabled={disabled}
      {...rest}
    >
      {content}
    </button>
  );
}

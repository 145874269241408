import React from "react";
import { Checkout } from "./CheckoutContext";
import PrimaryButton from "../shared/elements/PrimaryButton";

export function ContinueVippsPaymentLink({
  state,
  children = "Fortsett med Vipps-betaling",
}: {
  state: Checkout;
  children?: React.ReactNode;
}) {
  return <PrimaryButton href={state.vippsPaymentUrl}>{children}</PrimaryButton>;
}

export function ContinueCreditCardPaymentLink({
  state,
  children = "Fortsett med kortbetaling",
}: {
  state: Checkout;
  children?: React.ReactNode;
}) {
  return (
    <PrimaryButton href={state.creditCardPaymentUrl}>{children}</PrimaryButton>
  );
}

import React, { ButtonHTMLAttributes, useState } from "react";
import PrimaryButton from "../../shared/elements/PrimaryButton";
import { CheckoutClient } from "../CheckoutClient";

interface CreatePaymentButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {
  client: CheckoutClient;
  paymentType: "card" | "vipps";
  children: React.ReactNode;
}

export default function CreatePaymentButton({
  client,
  paymentType,
  disabled,
  ...props
}: CreatePaymentButtonProps) {
  const [isRedirecting, setIsRedirecting] = useState(false);

  return (
    <PrimaryButton
      {...props}
      type="submit"
      name={paymentType}
      disabled={client.isLoading || isRedirecting}
      onClick={async () => {
        setIsRedirecting(true);
        const result = await client.createPayment(paymentType);

        if (!result) {
          setIsRedirecting(false);
          return;
        }

        const { vippsPaymentUrl, creditCardPaymentUrl } = result;

        const url = paymentType === "vipps" ? vippsPaymentUrl : creditCardPaymentUrl;
        (window as any).location = url;
      }}
    />
  );
}

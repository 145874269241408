import React from "react";
import ScreenLayout from "../layout/ScreenLayout";
import PrimaryButton from "../elements/PrimaryButton";

export default function ErrorScreen({
  message,
  showReloadButton = false,
}: {
  message: string;
  showReloadButton?: boolean;
}) {
  return (
    <ScreenLayout title="Feil!">
      {message ? <p>{message}</p> : null}
      {showReloadButton ? (
        <PrimaryButton
          onClick={() => {
            window.location.reload();
          }}
        >
          Last på nytt
        </PrimaryButton>
      ) : null}
    </ScreenLayout>
  );
}

import React from "react";
import LoadingOverlay from "../shared/misc/LoadingOverlay";
import { useCheckoutClient } from "./CheckoutClient";
import CheckoutActions from "./CheckoutActions";

export default function CheckoutForm() {
  const client = useCheckoutClient();
  return (
    <form
      method="post"
      onSubmit={(e) => e.preventDefault()}
      style={{ position: "relative" }}
    >
      {client.isLoading ? <LoadingOverlay /> : null}
      <CheckoutActions client={client} />
      {client.error ? <p>En feil har oppstått! Vennligst prøv igjen.</p> : null}
    </form>
  );
}

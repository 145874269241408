import "./SummaryTable.css";
import React from "react";
import { useCheckoutState, CheckoutStatus, Checkout } from "./CheckoutContext";
import ScreenLayout from "../shared/layout/ScreenLayout";
import { formatMoneyCompact } from "../shared/misc/moneyString";
import CheckoutForm from "./CheckoutForm";
import CheckoutCompletePage from "./CheckoutCompletePage";

function Nok({ ore }: { ore: number }) {
  return <>{formatMoneyCompact(ore / 100)}&nbsp;kr</>;
}

export function Summary({ checkout }: { checkout: Checkout }) {
  if (checkout.orderLines)
    return (
      <table className="SummaryTable">
        <tbody>
          {checkout.orderLines.map(({ name, amount }) => (
            <tr key={name}>
              <td>{name}</td>
              <td>
                <Nok ore={amount} />
              </td>
            </tr>
          ))}
        </tbody>
        {checkout.showOrderLinesTotal ? (
          <tfoot>
            <tr>
              <td>Totalpris</td>
              <td>
                <Nok ore={checkout.amount} />
              </td>
            </tr>
          </tfoot>
        ) : null}
      </table>
    );

  return (
    <table className="SummaryTable">
      <tbody>
        <tr>
          <td>{checkout.description}</td>
          <td>
            <Nok ore={checkout.amount} />
          </td>
        </tr>
      </tbody>
    </table>
  );
}

export default function CheckoutLandingPage() {
  const checkout = useCheckoutState();

  const isComplete = checkout.status !== CheckoutStatus.None;

  if (isComplete) {
    return <CheckoutCompletePage checkout={checkout} />;
  }

  return (
    <>
      <ScreenLayout
        title="Velg betalingsmetode"
        backHref={checkout.completeUrl}
      >
        <Summary checkout={checkout} />
        <CheckoutForm />
      </ScreenLayout>
    </>
  );
}

import React, {
  createContext,
  useEffect,
  useCallback,
  useContext,
  useState,
  useMemo,
} from "react";
import { useParams, useLocation } from "react-router-dom";
import { useApiClient } from "../shared/api/ApiClient";
import ErrorScreen from "../shared/misc/ErrorScreen";
import LoadingScreen from "../shared/misc/LoadingScreen";
import { useWebSocketClient } from "../shared/api/WebSocketClient";

export enum CheckoutStatus {
  None = "None",
  Paid = "Paid",
  Refunded = "Refunded",
  Canceled = "Canceled",
}

export interface Checkout {
  id: string;
  amount: number;
  vatAmount: number;
  description: string;
  status: CheckoutStatus;
  creditCardIsSupported: boolean;
  vippsIsSupported: boolean;
  canContinuePayment: boolean;
  vippsPaymentUrl: string;
  creditCardPaymentUrl: string;
  customerId: string;
  completeUrl: string;
  orderLines: OrderLine[];
  showOrderLinesTotal: boolean;
  defaultPaymentMethod: "vipps" | "card";
}

export interface OrderLine {
  name: string;
  amount: number;
  vatAmount: number;
}

interface CheckoutContextType {
  checkoutId: string;
  isSubscribed: boolean;
  state: Checkout;
  setState: (state: Checkout) => void;
}

const CheckoutContext = createContext<CheckoutContextType | null>(null);

function useQuery() {
  const search = useLocation().search;

  return new URLSearchParams(search);
}

function useQueryParam(key: string) {
  const query = useQuery();

  return query.get(key) || undefined;
}

export function useCheckoutContext() {
  const context = useContext(CheckoutContext);

  if (!context)
    throw new Error(
      "No CheckoutContext present. Have you wrapped your app in a CheckoutContextProvider?"
    );

  return context;
}

export function useCheckoutId() {
  return useCheckoutContext().checkoutId;
}

export function useCheckoutState() {
  return useCheckoutContext().state;
}

export function useSignature() {
  return useQueryParam("signature");
}

function useLoadCheckoutState(checkoutId?: string, signature?: string) {
  const [state, setState] = useState<Checkout | null>(null);
  const client = useApiClient<Checkout>({ isLoadingInitially: true });
  const [error, setError] = useState<{ notFound: boolean } | null>(null);

  const webSocket = useWebSocketClient();

  const { onMessage, onOpen, send } = webSocket;
  const [isSubscribed, setIsSubscribed] = useState(false);

  const { fetch } = client;

  const load = useCallback(async () => {
    if (!checkoutId || !signature) return;

    const data = await fetch(
      `checkout/${checkoutId}/state?signature=${signature}`
    );

    if (data) setState(data);
    else setError({ notFound: true });
  }, [fetch, signature, checkoutId]);

  useEffect(() => {
    load();
  }, [load]);

  const subscribeToCheckout = useCallback(async () => {
    if (!checkoutId || !signature) return;

    await send({
      action: "SubscribeToCheckout",
      data: {
        checkoutId,
        signature,
      },
    });
    setIsSubscribed(true);
  }, [send, checkoutId, signature]);

  useEffect(() => onOpen(subscribeToCheckout), [onOpen, subscribeToCheckout]);
  useEffect(
    () =>
      onMessage((event) => {
        const { checkoutState } = JSON.parse(event.data);

        if (checkoutState) setState(checkoutState);
      }),
    [onMessage]
  );

  const isLoading = !isSubscribed || client.isLoading;

  return useMemo(
    () => ({
      state,
      setState,
      isLoading,
      isSubscribed,
      error,
    }),
    [state, isSubscribed, isLoading, error]
  );
}

export function useCheckoutParams() {
  const { checkoutId } = useParams<{ checkoutId: string }>();
  const signature = useSignature();

  return { checkoutId, signature };
}

export const CheckoutContextProvider: React.FunctionComponent<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { checkoutId, signature } = useCheckoutParams();
  const { state, setState, isLoading, error, isSubscribed } =
    useLoadCheckoutState(checkoutId, signature);

  if (!checkoutId || !signature) {
    return <ErrorScreen message="Denne lenken funka ikke!" />;
  }

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (error) {
    if (error.notFound)
      return <ErrorScreen message="Denne lenken funka ikke!" />;

    return (
      <ErrorScreen
        message="Kunne ikke hente denne siden akkurat nå."
        showReloadButton
      />
    );
  }

  if (!state) {
    return <LoadingScreen />;
  }

  return (
    <CheckoutContext.Provider
      value={{ checkoutId, state, setState, isSubscribed }}
    >
      {children}
    </CheckoutContext.Provider>
  );
};

export default CheckoutContext;

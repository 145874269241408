import React from "react";
import { useApiClient } from "../api/ApiClient";
import { useEffect } from "react";

export default function VersionInfo() {
  const { data, fetch } = useApiClient<{ version: string }>();

  useEffect(() => {
    fetch("/version");
  }, [fetch]);

  if (!data) return null;

  return (
    <span style={{ fontSize: ".8em" }}>
      v{process.env.REACT_APP_VERSION}
      {data ? `/${data.version}` : ""}
    </span>
  );
}

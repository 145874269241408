import { useState, useCallback } from "react";

export class ApiError extends Error {
  constructor(status: number, public data: any) {
    super(`Non-success status code ${status}`);
  }
}

export type HttpMethod = "get" | "post" | "put" | "delete" | "patch";

export interface ApiClient<T> {
  isLoading: boolean;
  error?: ApiError;
  data?: T;
  fetch(path: string, method?: HttpMethod, body?: any): Promise<T | undefined>;
}

export function useApiClient<T>({
  isLoadingInitially,
}: { isLoadingInitially?: boolean } = {}): ApiClient<T> {
  const [isLoading, setIsLoading] = useState(isLoadingInitially || false);
  const [error, setError] = useState<ApiError | undefined>();
  const [data, setData] = useState<T | undefined>();

  const doFetch = useCallback(
    async (path: string, method: HttpMethod = "get", body: any = null) => {
      setIsLoading(true);
      try {
        const uri = `/api/web${path.startsWith("/") ? path : `/${path}`}`;

        const init = {
          method,
          body: body ? JSON.stringify(body) : null,
          headers: body
            ? {
              "content-type": "application/json",
            }
            : undefined,
        };

        const response = await fetch(uri, init);
        const data = (await response.json()) as T;

        if (!response.ok) {
          const error = new ApiError(response.status, data);
          throw error;
        }

        setData(data);

        return data;
      } catch (error: any) {
        if (error instanceof ApiError || typeof error === 'undefined') {
          setError(error as ApiError | undefined);
        } else {
          console.error(error);
        }
      } finally {
        setIsLoading(false);
      }
    },
    []
  );

  return { isLoading, error, data, fetch: doFetch };
}
